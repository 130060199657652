<template>
  <div class="stracing">
    <div class="product_desc_1 box_pad">
      <h3>窜货带来的问题</h3>
      <p>
        <span>价格混乱:</span>
        由于各地销路不同，部分经销商会受到利益的驱动，使所经销的产品跨地区销售，打乱整个市场的价格体系。
      </p>
      <p>
        <span>恶意竞争:</span>
        由于价格出现的混乱，引起当地市场的价格战，价格危机导致通路利润降低，并激化了当地与跨地区商家的矛盾。
      </p>
      <div class="desc_img"></div>
      <p>
        <span>假货冒出:</span>
        由于价格逐渐走低，利润开始压缩，就很容易出现以低价为特征的假冒伪劣产品，加剧影响其他经销商与消费者的信任。
      </p>
      <p>
        <span>品牌受损:</span>
        经销商最直接动力是利润，价格混乱影响了对该品牌的信任，消费者也因买到该商品真假、混乱的价格与复杂的售后感到厌恶，品牌形象直线下滑。
      </p>
    </div>
    <div class="product_desc_2 box_pad">
      <h3>防窜货系统的优势</h3>
      <p>
        <span>订单统一管理:</span>
        一物一码防窜货系统订单统一管理，立即实现流通工作的系统管理，完善自己经销商管理体系。对各级代理、商品价格、订单、内部流控实现系统统一管理。
      </p>
      <p>
        <span>经销商分级管理:</span>
        实现经销商差异化管理;如对省级代理、地市代理、县级代理有不同的政策和价格体系。
      </p>
      <div class="desc_img"></div>

      <p>
        <span>商品及价格分级管理:</span>
        对商品信息统一管理：所有商品信息图片、参数、功能等信息都在系统内设置，一目了然。不同级别经销商不同价格。不同级别的经销商登录防窜货系统之后，可以看到本级别的商品进货价格，返点等信息，实现分级别管理。
      </p>
      <p>
        <span>订单处理流程化:</span>
        流程控制，分权管理，一物一码防窜货系统可对商品进全过程追踪监控，包括采购生产、运输物流、经销商管理、流通管理等全过程。
      </p>
    </div>
    <div class="product_desc_3 box_pad">
      <h3>防窜货系统的功能</h3>
      <p>
        防窜货系统包含系统管理、资料管理、产品管理、追溯码管理、计划管理、预警管理、入出库管理、库存管理、流向查询、库存查询、窜货查询、消费者查询、统计报表等功能模块。
      </p>
      <div class="desc_img"></div>

      <p>
        <span>质量管理:</span>
        产品全生命周期管理、全链数据追踪，真正做到质量安全管理、缺陷召回。
      </p>
      <p>
        <span>物流监控:</span>
        可实时查询库存状态和产品流向，保证库存管理轻松高效。
      </p>
      <p>
        <span>渠道管控:</span>
        实时准确采集渠道终端数据，有效监控产品流向、流量、流速。
      </p>
      <p>
        <span>预警管理:</span>
        各环节预警管理可提前把控各个节点的异常情况，管理更加轻松高效。
      </p>
      <p>
        <span>智能分析:</span>
        通过数据挖掘，帮助企业实现各个节点管理的分析统计报表，为企业决策提供支持。
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.stracing {
  .box_pad {
    padding: 50px 30px;
  }
  h3 {
    font-size: 50px;
    font-weight: 700;
  }
  p {
    text-align: justify;
    font-size: 35px;
    color: #333;
    margin-bottom: 20px;
  }
  span {
    font-size: 35px;
    font-weight: 500;
  }
  .desc_img {
    width: 100%;
    height: 400px;
    border: 2px solid #f0f0f0;
    background: url("~@/assets/img/product/goodstracing_1.png") no-repeat;
    background-size: cover;
    background-position: -20px;
    border-radius: 10px;
    margin: 10px 0;
    margin-bottom: 20px;
  }
  .product_desc_2 {
    background-color: #f7f7f7;
    .desc_img {
      background: url("~@/assets/img/product/goodstracing_2.jpg") no-repeat
        center/cover;
    }
  }
  .product_desc_3 {
    .desc_img {
      background: url("~@/assets/img/product/intelligent_info_process.png")
        no-repeat center/contain;
    }
  }
}
</style>